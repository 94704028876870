<template>
  <div class="SliderMainViewerVideo" :style="{width: width + '%'}">


    <div class="SliderMainViewerVideo--container">

      <div class="SliderMainViewerVideo--container--content">

<!--       <div class="SliderMainViewerVideo--container--content--img-container" :style="{width: navbarWidth + 'px'}">

        <template v-if="width == 100" >
        <img class="SliderMainViewerVideo--container--content--img-container--img" src="@/assets/images/nav_big.png" :style="{width: navbarWidth + 'px'}">
        </template>

        <template v-if="width == 50">
        <img class="SliderMainViewerVideo--container--content--img-container--img" src="@/assets/images/nav_half.png" :style="{width: navbarWidth + 'px'}">
        </template>

      </div> -->

      <video class="SliderMainViewerVideo--container--content--video" 
      loop muted playsinline ref="videoplayer">
        <source :src="content" type="video/mp4">
      </video>
      <!-- :poster="poster" -->

      </div>

    </div>

  </div>
</template>

<script>

export default {
  name: 'SliderMainViewerVideo',
  props: ['content', 'poster', 'context', 'width'],
  data: function(){
    return {
      index: 0,
      navbarWidth: ''
    }
  },
  components: {
  },
  computed:{



  },
  methods: {

    whatNavbarWidth: function(){
      if (this.$refs.videoplayer){
        this.navbarWidth = this.$refs.videoplayer.getBoundingClientRect().width
      }
    }

  },
  created(){
    setTimeout(() => { this.$el.style.opacity = 1 }, 600);

    setTimeout(() => { 
      if (this.$refs.videoplayer){
        this.$refs.videoplayer.play()
      } 
    }, 900);
  },
  mounted(){

    this.whatNavbarWidth()

    setTimeout(() => { this.whatNavbarWidth() }, 500);

  },

  beforeDestroy(){
    // console.log('viewer video destroy')
  }
  
}
</script>

<style lang="scss">
@import '@/css/variables';

.SliderMainViewerVideo{
  position: absolute;
  // width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; 

  z-index: 44;

  opacity: 0;
  
  &--container{

    height: 100%;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; 

    &--content{

      width: 100%;
      height: 100%;

      // max-width: 80%;
      // max-height: 80%;

      max-width: 80.1%;
      max-height: 80.1%;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; 

      &--video{
        
        width: 100%;
        height: 100%;
        max-width: 100%;
        max-height: 100%;
        // max-height: 96.663%;

        // object-fit: contain;
      }

      &--img-container{

        width: 100%;

        // object-fit: contain;

        &--img{
          width: 100%;
          height: 100%;
          // object-fit: contain;
        }
        
      }
    }
  }
}

</style>