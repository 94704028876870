<template>

  <transition name="slideView" mode="out-in">

  <div class="InitScreen">

    <div class="InitScreen--container" :class="'container-' + context">
      
      <div class="InitScreen--container--left" :class="'container-left-' + context">
        <div class="InitScreen--container--left--container" @mouseenter="mouseEnter('left')" ref="area_left">
          <div class="InitScreen--container--left--container--content"
          :class="'content-' + context"
          ><span :class="initscreenIsActive == false ? 'letterTitle isHiding' : ''">K</span>rier</div>
        </div>
      </div>

      <div class="InitScreen--container--right" :class="'container-right-' + context">
        <div class="InitScreen--container--right--container" @mouseenter="mouseEnter('right')" ref="area_right">
          <div class="InitScreen--container--right--container--content"
          :class="'content-' + context"
          ><span :class="initscreenIsActive == false ? 'letterTitle isHiding' : ''">G</span>atard</div>
        </div>
      </div>

    </div>

  </div>

  </transition>
</template>

<script>

export default {
  name: 'InitScreen',
  props: ['content', 'context', 'initscreenIsActive'],
  data: function(){
    return {
    }
  },
  components: {},
  computed:{


  },
  methods: {
    getRandom: function(max){
      return Math.floor(Math.random() * Math.floor(max));
    },


    mouseEnter: function(area){
      let newRandom = this.getRandom(this.content.length)
      let newColor = this.content[newRandom]
      newRandom, newColor
      if ( area == 'left'){
        this.$refs.area_left.style.backgroundColor = newColor
        this.$refs.area_right.style.backgroundColor = "white"
        // this.$refs.area_right.style.backgroundColor = "white"
        // this.$refs.area_right.style.color = "black"
        // this.$refs.area_left.style.backgroundColor = "black"
        // this.$refs.area_left.style.color = "white"

      }else {
        this.$refs.area_left.style.backgroundColor = "white"
        this.$refs.area_right.style.backgroundColor = newColor
        // this.$refs.area_left.style.backgroundColor = "white"
        // this.$refs.area_left.style.color = "black"
        // this.$refs.area_right.style.backgroundColor = 'black'
        // this.$refs.area_right.style.color = "white"
      }
    }
  },
  created(){
    // console.log(this.content)
  }
  
}
</script>

<style lang="scss">
@import '@/css/variables';

.InitScreen{
  width: 100%;
  height: 100%;

  // filter: blur(10px);

  &--container{

    display: flex;
    width: 100%;
    height: 100%;

    &.container-mobile{
      flex-direction: column;
    }

    &--left{
      width: 50%;
      height: 100%;
      // background-color: red;
      overflow: hidden;
      // transform: translate(-50%);

      &.container-left-mobile{
        width: 100%;
        height: 50%;
      }

      &--container{
        background-color: white;
        height: 100%;

        // display: flex;
        // flex-direction: column;
        // justify-content: center;
        // align-items: center;
        padding: var(--home-esp-big);
        margin: var(--esp-med);
        line-height: 0.8;

        &--content{
          // font-size: 60vw;

          // font-size: min(max(1px, 95vh), 60vw);
          // font-size: min(max(1px, 120vh), 68vw);
          // transform: translateX(-5%);



          font-size: 12vw;

          &.content-mobile{
            // font-size: min(max(1px, 55vh), 90vw);
            font-size: var(--home-font-title);
          }
        }
      }
    }

    &--right{
      width: 50%;
      height: 100%;
      
      overflow: hidden;
      // transform: translate(-50%);

      &.container-right-mobile{
        width: 100%;
        height: 50%;
      }

      &--container{
        height: 100%;
        // display: flex;
        // flex-direction: column;
        // justify-content: center;
        // align-items: center;

        padding: var(--home-esp-big);
        line-height: 0.8;

        &--content{
          // font-size: 60vw;
          
          // font-size: min(max(1px, 95vh), 60vw);
          // font-size: min(max(1px, 120vh), 68vw);

          font-size: 12vw;

          &.content-mobile{
            // font-size: min(max(1px, 55vh), 90vw);
            font-size: var(--home-font-title);
          }
        }
      }
    }
  }
}

.letterTitle{

  &.isHiding{
    opacity: 0;
  }
}



</style>