<template>
  <div class="SliderMain">

    <div class="SliderMain--nav-container" :class="'nav-container-' + context">
      <div class="SliderMain--nav-container--left" :class="'nav-container-left-' + context"
      @click="setIndex(-1)"></div>
      <div class="SliderMain--nav-container--right" :class="'nav-container-right-' + context"
      @click="setIndex(+1)"></div>
    </div>


    <div class="SliderMain--video-viewer" v-if="content[childIndex].hasVideo == 'true'">
      <SliderMainViewerVideo :content="content[childIndex].video" :poster="content[childIndex].video_poster" :width="100"></SliderMainViewerVideo>
    </div>

    <div class="SliderMain--half-video-viewer" v-if="content[childIndex].left.hasVideo == 'true' || content[childIndex].right.hasVideo == 'true'">
      <div class="SliderMain--half-video-viewer--left">
        <template v-if="content[childIndex].left.hasVideo == 'true'">
        <SliderMainViewerVideo :content="content[childIndex].left.video" :poster="content[childIndex].left.video_poster" :width="50"></SliderMainViewerVideo>
        </template>
      </div>
      <div class="SliderMain--half-video-viewer--right">
        <template v-if="content[childIndex].right.hasVideo == 'true'">
        <SliderMainViewerVideo :content="content[childIndex].right.video" :poster="content[childIndex].left.video_poster" :width="50"></SliderMainViewerVideo>
        </template>
      </div>
    </div>

    <div class="SliderMain--title-container" :class="'title-container-' + context" :style="{opacity: legendeOpacity}">

      <div class="SliderMain--title-container--left" :class="'title-container-left-' + context">
        <div class="SliderMain--title-container--left--container" :style="{transform: whatInitTranslate }">
          <div class="SliderMain--title-container--left--container--title" >
            <p class="title-font" 
            :style="{cursor: this.context == 'mobile' ? 'pointer' : 'pointer'}" 
            @click="setMainInfo(0)" 
            @mouseenter="titleMouseenter()"
            @mouseleave="titleMouseleave()">
            K</p>
          </div>
        </div>
      </div>
      <div class="SliderMain--title-container--right" :class="'title-container-right-' + context">
        <div class="SliderMain--title-container--right--container" :style="{transform: whatInitTranslate }">
          <div class="SliderMain--title-container--right--container--title">
            <p class="title-font" 
            :style="{cursor: this.context == 'mobile' ? 'pointer' : 'pointer'}" 
            @click="setMainInfo(1)"
            @mouseenter="titleMouseenter()"
            @mouseleave="titleMouseleave()">
            G</p>
          </div>
        </div>
      </div>

    </div>

    <div class="SliderMain--legendes-container" :class="'legendes-container-' + context" 
    :style="{opacity: legendeOpacity}">

      <template v-if="context == 'mobile'"> 

      <div 
      class="SliderMain--legendes-container--left" 
      :class="'legendes-container-left-' + context">

        <div class="SliderMain--legendes-container--left--text-container" :class="'text-container-left-mobile'">
          <div class="SliderMain--legendes-container--left--text-container--title" v-html="content[childIndex].title">
            
          </div>
<!--           <div class="SliderMain--legendes-container--left--text-container--description">
            {{content[childIndex].description}}
          </div> -->

        </div>
      </div>

      <div 
      class="SliderMain--legendes-container--right" 
      :class="'legendes-container-right-mobile'">
        <div class="SliderMain--legendes-container--right--text-container" :class="'text-container-right-mobile'">
          <div class="SliderMain--legendes-container--right--text-container--title" v-html="content[childIndex].title">
            <!-- {{content[childIndex].title}} -->
          </div>
<!--           <div class="SliderMain--legendes-container--right--text-container--description">
            {{content[childIndex].description}}
          </div> -->
        </div>
      </div>

      </template>

      <template v-if="context != 'mobile'">

      <div 
      class="SliderMain--legendes-container--left" 
      :class="'legendes-container-left-' + context">

        <div class="SliderMain--legendes-container--left--text-container" 
        :style="{color:content[childIndex].left.legendeColor}">

          <div class="SliderMain--legendes-container--left--text-container--title" v-html="content[childIndex].title">
            <!-- {{content[childIndex].title}} -->
          </div>


          <div class="SliderMain--legendes-container--left--text-container--description">
            {{content[childIndex].description}}
          </div>

        </div>
      </div>



      </template>

    </div>

    <div class="SliderMain--sliders-container" :class="'sliders-container-' + context">

      <SliderMain50 
      :content="content" 
      :halfSlider="'left'" 
      :parentIndex="index" 
      :context="context"
      :initscreenIsActive="initscreenIsActive" 
      @receipe-child-index="setChildIndex">
      </SliderMain50>
      <SliderMain50 
      :content="content" 
      :halfSlider="'right'" 
      :parentIndex="index" 
      :context="context"
      :initscreenIsActive="initscreenIsActive">
      </SliderMain50>

    </div>

    <div class="SliderMain--background-container"></div>

  </div>
</template>

<script>
import SliderMain50 from '@/components/slidermain/SliderMain50.vue'
import SliderMainViewerVideo from '@/components/slidermain/SliderMainViewerVideo.vue'

export default {
  name: 'SliderMain',
  props: ['content', 'context', 'initscreenIsActive'],
  components: {
    SliderMain50,
    SliderMainViewerVideo
  },
  data: function(){
    return {
      index: 0,
      childIndex: 0,

      legendeProject: '',

      legendeOpacity: 0
    }
  },
  watch: {

    initscreenIsActive: function(){

      setTimeout(() => { this.legendeOpacity = 1 }, 0);

    }

  },
  computed:{

    whatInitTranslate: function(){

      if (this.initscreenIsActive){
        // return 'translate('+ 50 + 'vw)'
        return 'translate('+ 0 + 'vw)'

      }else {
        return 'translate('+ 0 + 'vw)'
      }
    }

  },
  methods: {

    titleMouseenter: function(){
      // console.log('titleMouseenter')
      this.$emit('set-cursor-activ', false)
    },

    titleMouseleave: function(){
      // console.log('titleMouseleave')
      this.$emit('set-cursor-activ', true)
    },




    setChildIndex: function(index){
      // console.log('setChildIndex ', index)
      this.childIndex = index
    },

    setMainInfo: function(index){

      if(this.context == 'mobile'){
        this.$emit('set-maininfo', 'mobile')
      }else {
        this.$emit('set-maininfo', index)
      }

    },

    setIndex: function(direction){
      this.index = this.index + direction
      // console.log('setIndex', this.index)
    },

  },
  created(){
    // console.log(this.content)
  }
  
}
</script>

<style lang="scss">
@import '@/css/variables';
@import '@/css/transition';

.SliderMain{
  position: relative;
  width: 100%;
  height: 100%;



  &--nav-container{
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;

    z-index: 50;

    &.nav-container-mobile{
      // flex-direction: column;
    }

    &--left{
      width: 50%;
      height: 100%;

      &.nav-container-left-mobile{
        // height: 50%;
        // width: 100%;
        height: 100%;
        width: 50%;

        cursor: pointer;
      }
    }

    &--right{
      width: 50%;
      height: 100%;

      &.nav-container-right-mobile{
        // height: 50%;
        // width: 100%;
        height: 100%;
        width: 50%;

        cursor: pointer;
      }
    }
  }

  &--legendes-container{
    position: absolute;
    width: 100%;
    height: 100%;
      
    display: flex; 
    pointer-events: none;

    z-index: 55;

    // transition: opacity 0.9 ease;

    &.legendes-container-mobile{
      flex-direction: column;
    }

    &--left{
      // position: relative;
      width: 50%;
      height: 100%;

      pointer-events: none;
      overflow: hidden;

      

      &.legendes-container-left-mobile{
        // position: absolute;
        width: 100%;
        height: 100%;
      }

      &--text-container{
        position: absolute;

        bottom: var(--home-esp-big);
        // bottom: calc(50% + var(--home-esp-big));
        left: var(--home-esp-big);
        font-size: var(--home-font-description);
        line-height: 1.2;

        width: calc(50% - 2 * var(--home-esp-big));

        &.text-container-left-mobile{
          width: calc(100% - 2 * var(--home-esp-big));
          bottom: calc(50% + var(--home-esp-big));
        }

        &--title{
          padding-top: 1vw;
          // padding-bottom: var(--home-esp-small);
          line-height: 1.4;

          // pointer-events: auto;

          & a{
            // color: green;
            color: inherit;
            // text-decoration: underline;
          }

          & a:after {
            // content: "➔ Voir le site";
            content: "Voir le site";
            position: relative;
            left: 1vw;
            text-decoration: underline;
            pointer-events: auto;
            font-family: 'ital';
          }
        }

        &--description{
          // padding-top: var(--home-esp-small);
          line-height: 1.2;
        }

      }
    }

    &--right{
      width: 50%;
      height: 100%;

      pointer-events: none;
      // transform: translate(50vw);
      overflow: hidden;
      // filter: grayscale(1);



      &.legendes-container-right-mobile{
        width: 100%;
        height: 100%;

      } 

      &--text-container{
        position: absolute;

        bottom: var(--home-esp-big);
        left: var(--home-esp-big);
        font-size: var(--home-font-description);
        line-height: 1.2;

        &--title{
          padding-top: 1vw;
          line-height: 1.4;
        }

        &--description{
          // padding-top: var(--home-esp-small);
          line-height: 1.2;
        }

      }
    }

  }

  &--title-container{
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 55;

    display: flex;

    pointer-events: none;

    mix-blend-mode: difference;
    // mix-blend-mode: lighten;





    &.title-container-mobile{
      flex-direction: column;
    }

    &--left{
      width: 50%;
      height: 100%;

      pointer-events: none;
      overflow: hidden;

      &.title-container-mobile-left{
        width: 100%;
        height: 50%;
      }  

      &--container{

        transition: transform 0.5s ease;
        pointer-events: none;

        &--title{
          line-height: 0.8;
          margin: var(--home-esp-big);
          font-size: var(--home-font-title);
          pointer-events: auto;
          // cursor: pointer;
        }
      }
    }

    &--right{
      width: 50%;
      height: 100%;

      pointer-events: none;
      // transform: translate(50vw);
      overflow: hidden;
      // filter: grayscale(1);

      &.title-container-mobile-right{
        width: 100%;
        height: 50%;

      } 

      &--container{
        // width: 100%;
        // height: 100%;

        transition: transform 0.5s ease;

        pointer-events: none;

        // filter: grayscale(1);

        &--title{
          line-height: 0.8;
          margin: var(--home-esp-big);
          font-size: var(--home-font-title);
          pointer-events: auto;
          // cursor: pointer;

          // filter: grayscale(1);
          // color: white;
        }
      }
    }
  }

  &--video-viewer{
    position: absolute;
    width: 100%;
    height: 100%;
  }

  &--half-video-viewer{
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;

    z-index: 40;

    &--left{
      width: 50%;
      height: 100%;
    }

    &--right{
      width: 50%;
      height: 100%;
    }
  }

  &--sliders-container{
    position: relative;
    width: 100%;
    height: 100%;

    display: flex;

    &.sliders-container-mobile{
      flex-direction: column;
    }
  }

  &--background-container{

  }
}



.SliderMain--title-container--right--container--title, .SliderMain--title-container--left--container--title{
  // mix-blend-mode: exclusion;
  // background-color: black;
  height: 20%;
  width: 100%;
}

.title-font{
  position: absolute;
  // background-color: black;

  color: white;
  // mix-blend-mode: exclusion;
  // background-color: grey;
  // background: white;
  
  // mix-blend-mode: exclusion;
  // mix-blend-mode: hard-light;
}




</style>