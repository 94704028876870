<template>
  <div class="SliderMain50Slide" :class="'SliderMain50Slide-' + context">

    <div class="SliderMain50Slide--container">

<!--     <template v-if="halfSlider == 'left' && this.context != 'mobile'">
   
      <div class="SliderMain50Slide--container--text-container" 
      v-if="halfSlider == 'left'"
      :style="{color: whatLegendeColor()}">
        <div class="SliderMain50Slide--container--text-container--title">{{content.title}}</div>
        <div class="SliderMain50Slide--container--text-container--description">{{content.description}}</div>
      </div>   

    </template> -->

<!--     <template v-if="this.context == 'mobile'">
 
      <div class="SliderMain50Slide--container--text-container"
      v-if="halfSlider == 'left'" 
      :style="{color: whatLegendeColor()}">
        <div class="SliderMain50Slide--container--text-container--title">{{content.title}}</div>
        <div class="SliderMain50Slide--container--text-container--description">{{content.description}}</div>
      </div> 

      <div class="SliderMain50Slide--container--text-container"
      v-if="halfSlider == 'right'" 
      :style="{color: whatLegendeColor()}">
        <div class="SliderMain50Slide--container--text-container--title">{{content.title}}</div>
        <div class="SliderMain50Slide--container--text-container--description">{{content.description}}</div>
      </div>   

    </template> -->

    <div class="SliderMain50Slide--container--img-container" :class="[whatClassContainer()]" :style="{'background-color': whatBackgroundColor()}">
      <img class="SliderMain50Slide--container--img-container--img" 
      :class="whatClassContainerImg()" 
      :src="content.image.thumb"
      :data-srcset="content.image.srcset"
      :data-url="content.image.url"
      :sizes="context == 'mobile' ? '150vw' : '75vw'"
      ref="img">
    </div>

    <!-- :data-srcset="content.image.srcset" -->

  </div>

  </div>
</template>

<script>
export default {
  name: 'SliderMain50Slide',
  props: ['content', 'context', 'halfSlider', 'index', 'isLoaded'],
  data: function(){
    return {
      srcsetLoaded: false
    }
  },
  watch: {

    isLoaded:{
      immediate: true,
      handler(val){
        if (val && !this.srcsetLoaded){
          //srcset
          this.srcsetLoaded = true
          this.setSrcset()
        }
      }
    }

  },

  computed:{

  },
  methods: {

    setSrcset: function(){
      let url = this.$refs.img.dataset.url
      let srcset = this.$refs.img.dataset.srcset

      if (url.includes('gif')){
        this.$refs.img.src =  url
      }else {
        this.$refs.img.srcset =  srcset
      }

    },

    // left / right
    // simple / double
    // 100% / 80%
    // color / not color

    // video / not video

    whatBackgroundColor: function(){
      // color / not color

      // if this.content.hasColor
      return this.content.color
    },

    whatClassContainer: function(){
      // left / right
      // 100% / 80%

      if (this.content.position == 'true'){ // cover

        return ''

      }else { // 80%

        if (this.content.isFull == 'true'){

          if (this.halfSlider == 'left'){

            // return 'slide-flex-left' 

            // return 'slide-flex-left' + this.context

            if (this.context == 'mobile'){
              return 'slide-flex-left' + this.context
            }{
              return 'slide-flex-left' 
            }
          }else {

            // return 'slide-flex-right'

            // return 'slide-flex-right' + this.context

            if (this.context == 'mobile'){
              return 'slide-flex-right' + this.context
            }else {
              return 'slide-flex-right'
            }
          }

        }else {

          return 'slide-flex-center'
        }
      }
    },

    whatLegendeColor: function(){
      // console.log(this.content.legendeColor)

      if (this.content.legendeColor){
        // console.log(this.content.legendeColor)
        return this.content.legendeColor
      }else {
        return 'black'
      }
    },

    whatClassContainerImg: function(){
      // left / right
      // simple / double
      // 100% / 80%

      let selectClass

      if (this.halfSlider == 'left'){

        if (this.content.isFull == 'true'){

          if (this.content.position == 'false'){
            // selectClass = 'js-double-left slide-flex-img-double'

            // selectClass = 'js-double-left' + this.context + ' slide-flex-img-double'

            if (this.context == 'mobile'){
              selectClass = 'js-double-left' + this.context + ' slide-flex-img-double'
            }else{
              selectClass = 'js-double-left slide-flex-img-double'
            }

          }else {
            // selectClass = 'js-double-left slide-flex-img-simple'
            // selectClass = 'js-double-left' + this.context + ' slide-flex-img-simple'

            if (this.context == 'mobile'){
              selectClass = 'js-double-left' + this.context + ' slide-flex-img-simple'
            }else {
              selectClass = 'js-double-left slide-flex-img-simple'
            }
          }

        }else {
          // selectClass = 'js-simple'

          if (this.content.position == 'false'){
            selectClass = 'js-simple-flex'
          }else {
            selectClass = 'js-simple-full'
          }
        }

      }else {

        if (this.content.isFull == 'true'){

          if (this.content.position == 'false'){
            // selectClass = 'js-double-right slide-flex-img-double'
            // selectClass = 'js-double-right' + this.context + ' slide-flex-img-double'

            if (this.context == 'mobile'){
              selectClass = 'js-double-right' + this.context + ' slide-flex-img-double'
            }else {
              selectClass = 'js-double-right slide-flex-img-double'
            }

          }else {
            // selectClass = 'js-double-right slide-flex-img-simple'
            // selectClass = 'js-double-right' + this.context + ' slide-flex-img-simple'

            if ( this.context == 'mobile'){
              selectClass = 'js-double-right' + this.context + ' slide-flex-img-simple'
            }else {
              selectClass = 'js-double-right slide-flex-img-simple'
            }
          }

        }else {
          // selectClass = 'js-simple'

          if (this.content.position == 'false'){
            selectClass = 'js-simple-flex'
          }else {
            selectClass = 'js-simple-full'
          }
        }

      }

      // console.log(selectClass)

      return selectClass
    }

  },
  created(){
    // console.log(this.content)

    // console.log(this.context)

    // setTimeout(() => { console.log(this.index, this.isLoaded) }, 200);

  }
  
}
</script>

<style lang="scss">
@import '@/css/variables';

.SliderMain50Slide{
  position: relative;
  width: 50vw;
  height: 100%;

  &.SliderMain50Slide-mobile{
    height: 100%;
    width: 100vw;
  }

  &--container{
    position: relative;
    width: 100%;
    height: 100%;

    &--text-container{
      position: absolute;
      z-index: 30;

      bottom: var(--home-esp-big);
      left: var(--home-esp-big);

      z-index: 1000;

      font-size: var(--home-font-description);

      color: white;

      line-height: 1.2;

      &--title{
        padding-top: 1vw;
        // padding-bottom: var(--home-esp-small);
        line-height: 1.4;
      }

      &--description{
        // padding-top: var(--home-esp-small);
        line-height: 1.2;
      }



    }

    &--img-container{
      position: relative;
      width: 100%;
      height: 100%;
      z-index: 25;

      &--img{
        object-fit: cover;
        isolation: isolate;
      }
    }
  }
}


.slide-flex-left{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.slide-flex-right{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.slide-flex-center{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.slide-flex-img-simple{
  width: 100%;
  height: 100%;
}

.slide-flex-img-double{
  // test img plein format
  width: 100%;
  height: 100%;
  object-fit: contain;

  max-width: 80%;
  max-height: 80%;
}



.js-simple-full{
  width: 100%;
  height: 100%;
  object-position: 50% 50%;
}

.js-simple-flex{
  // test img plein format
  width: 100%;
  height: 100%;
  object-fit: contain;

  max-width: 80%;
  max-height: 80%;
  object-position: 50% 50%;
}

.js-double-left{
  object-position: 100% 50%;
}

.js-double-right{
  object-position: 0% 50%;
}



.slide-flex-leftmobile{
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.slide-flex-rightmobile{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}


.js-double-leftmobile{
  object-position: 50% 100%;
}

.js-double-rightmobile{
  object-position: 50% 0%;
}

</style>