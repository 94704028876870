<template>
  <div class="SliderMain50" :class="'SliderMain50-' + context ">

    <div class="SliderMain50--container" :style="{transform: whatInitTranslate }">

      <div class="SliderMain50--container--content" 
      :class="['SliderMain50--container--content-' + context,'content-' + 'halfSlider-' + context]"
      ref="slider"
      :style="{width: 100 + this.content.length * 100 + '%', transform: whatTranslate }">

        <template v-if="context != 'mobile'">

          <div :style="{opacity: firstSlideisVisible ? 1 : 0}">
            <SliderMain50Slide 
            :content="whatContentSlider(content.length - 1)" 
            :index="content.length - 1" 
            :halfSlider="halfSlider" 
            :isLoaded="slideIsLoaded[index]">
            </SliderMain50Slide>
          </div>
          
          <div v-for="(element, index) in content" :key="index">
            <SliderMain50Slide :content="whatContentSlider(index)" 
            :index="index" 
            :context="context"
            :halfSlider="halfSlider" 
            :isLoaded="slideIsLoaded[index]">
            </SliderMain50Slide>
          </div>

          <div>
            <SliderMain50Slide :content="whatContentSlider(0)" 
            :index="0" 
            :context="context"
            :halfSlider="halfSlider" 
            :isLoaded="slideIsLoaded[index]">
            </SliderMain50Slide>
          </div>

        </template>

        <template v-if="context == 'mobile'">

          <template v-if="halfSlider == 'left'">

            <div :style="{opacity: firstSlideisVisible ? 1 : 0, }" >
              <SliderMain50Slide 
              :content="whatContentSlider(content.length - 1)" 
              :index="content.length - 1" 
              :halfSlider="halfSlider" 
              :isLoaded="slideIsLoaded[index]">
              </SliderMain50Slide>
            </div>
            
            <div v-for="(element, index) in content" :key="index" :style="{}">
              <SliderMain50Slide :content="whatContentSlider(index)" 
              :index="index" 
              :context="context"
              :halfSlider="halfSlider" 
              :isLoaded="slideIsLoaded[index]">
              </SliderMain50Slide>
            </div>

            <div :style="{}">
              <SliderMain50Slide :content="whatContentSlider(0)" 
              :index="0" 
              :context="context"
              :halfSlider="halfSlider" 
              :isLoaded="slideIsLoaded[index]">
              </SliderMain50Slide>
            </div>     

          </template>

          <template v-if="halfSlider == 'right'" >

            <div :style="{opacity: firstSlideisVisible ? 1 : 0, }">
              <SliderMain50Slide 
              :content="whatContentSlider(content.length - 1)" 
              :index="content.length - 1" 
              :halfSlider="halfSlider" 
              :isLoaded="slideIsLoaded[index]">
              </SliderMain50Slide>
            </div>
            
            <div v-for="(element, index) in content" :key="index">
              <SliderMain50Slide :content="whatContentSlider(index)" 
              :index="index" 
              :context="context"
              :halfSlider="halfSlider" 
              :isLoaded="slideIsLoaded[index]">
              </SliderMain50Slide>
            </div>

            <div :style="{}">
              <SliderMain50Slide :content="whatContentSlider(0)" 
              :index="0" 
              :context="context"
              :halfSlider="halfSlider" 
              :isLoaded="slideIsLoaded[index]">
              </SliderMain50Slide>
            </div> 

          </template>

        </template>

      </div>

    </div>

  </div>
</template>

<script>
import SliderMain50Slide from '@/components/slidermain/SliderMain50Slide.vue'

export default {
  name: 'SliderMain50',
  props: ['content', 'halfSlider', 'context', 'parentIndex' ,'initscreenIsActive'],
  data: function(){
    return {
      firstSlideisVisible : false,

      index: 0,

      tabIsLoaded: [],
    }
  },
  components: {
    SliderMain50Slide
  },
  watch:{
    initscreenIsActive: function(){
      setTimeout(() => { this.firstSlideisVisible = true }, 500);
    },

    parentIndex: function(newValue,oldvalue){
      let diffValue = oldvalue - newValue > 0 ? -1 : 1

      if (this.index == (this.content.length - 1) && diffValue > 0){ // max
        // console.log('max')
        this.restartSlider()
        this.setTabisLoaded(0)

      }else if (this.index == 0 && diffValue < 0) { // min
        this.reverseSlider()
        this.setTabisLoaded(this.content.length - 1)

      }else{
        this.index += diffValue
        this.sendIndex()
        this.setTabisLoaded()
      }
    }
  },
  computed:{

    slideIsLoaded: function(){
      return this.tabIsLoaded.map(function(item) {
          return item;
      });
    },

    whatTranslate: function(){
      if ( this.context == 'mobile'){
        let translateValue = -1 * this.index * 100 - 100
        return 'translate('+ translateValue + 'vw)'
      }else {

        let translateValue = -1 * this.index * 50 - 50
        return 'translate('+ translateValue + 'vw)'
      }
    },

    whatInitTranslate: function(){

      if (this.initscreenIsActive){

        if ( this.context == 'mobile'){

          return 'translate('+ 100 + 'vw)'
        }else {

          return 'translate('+ 50 + 'vw)'
        }

      }else {
        return 'translate('+ 0 + 'vw)'
      }
    }


  },
  methods: {

    initTabisLoaded: function(){

      this.content.forEach((element, index) => this.$set(this.tabIsLoaded, index, false))

      this.$set(this.tabIsLoaded, 0, true)
      this.$set(this.tabIsLoaded, this.content.length - 1, true)
    },

    setTabisLoaded: function(manualIndex){

      let index = manualIndex ? manualIndex : this.index

      this.$set(this.tabIsLoaded, index, true)

      if (index != this.content.length - 1){
        this.$set(this.tabIsLoaded, index + 1, true)
      }

      if (index != 0){
        this.$set(this.tabIsLoaded, index - 1, true)
      }
    },

    sendIndex: function(){
      this.$emit('receipe-child-index', this.index)
    },

    whatContentSlider: function(index){

      if (this.halfSlider == 'left'){
        return this.content[index].left
      }else {
        return this.content[index].right
      }
    },

    restartSlider: function(){

      this.$refs.slider.style.transition = 'transform 0s ease'; 
      this.index = -1

      setTimeout(() => { 
        this.$refs.slider.style.transition = 'transform 0.5s ease'; 
        this.index = 0
        this.$emit('receipe-child-index', this.index)
      }, 20);
    },

    reverseSlider: function(){

      this.$refs.slider.style.transition = 'transform 0s ease'; 
      this.index = this.content.length

      setTimeout(() => { 
        this.$refs.slider.style.transition = 'transform 0.5s ease'; 
        this.index = this.content.length - 1
        this.$emit('receipe-child-index', this.index)
      }, 20);
    },

  },
  mounted(){
    // console.log(this.content)

    this.initTabisLoaded()
  }
  
}
</script>

<style lang="scss">
@import '@/css/variables';

.SliderMain50{
  width: 50%;
  height: 100%;
  overflow: hidden;

  // background-color: green;

  &.SliderMain50-mobile{
    height: 50%;
    width: 100%;
  }

  &--container{
    height: 100%;
    width: 100%;
    // transform: translate(50vw);
    transition: transform 0.5s ease;

    &--content{
      display: flex;
      height: 100%;
      transition: transform 0.5s ease;
      // transform: translate(-200vw);
      // 
      

      // background-color: red;
      
      & div {
        width: var(--slide-width);
      }
    }
  }
}



</style>